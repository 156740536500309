$system-default-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';

$heading-font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
$heading-font-weight: 600;

$content-font-family: 'Open Sans', Arial, sans-serif;
$content-font-weight: 400;

%font-heading {
  font-family: $heading-font-family !important;
  font-weight: $heading-font-weight !important;
  color: $color-heading-text !important;
}

%font-content {
  font-family: $content-font-family !important;
  font-weight: $content-font-weight !important;
  color: $color-text !important;
}
