// navigation
header {
    position: relative;
    z-index: 10;
    background: $white;
    box-shadow: 0 0 5px 0 rgba($dark, 0.5);

    h3 {
        display: inline-block;
    }

    ul {
        float: right;
        text-align: right;

        li {
            margin-top: 4px;
            margin-left: 6px;
            a {
                display: block;
                padding: 5px;
            }
        }
    }

    @media screen and (max-width: 742px) {
        h3 {
            display: block;
            text-align: center;
        }
        ul {
            float: none;
            text-align: center;
        }
    }
}

// login
#login {
    background: linear-gradient(to right, $primary 0%, $primary 50%, $white 50%, $white 100%);

    @media screen and (max-width: 742px) {
        height: 100vh;
        background: $white;
    }

    .col1,
    .col2 {
        height: 100vh;
        padding-top: 30vh;

        @media screen and (max-width: 742px) {
            height: auto;
            padding-top: 20vh;
        }
    }
    .col1 {
        color: $white;

        @media screen and (max-width: 742px) {
            display: none;
        }

        p {
            max-width: 490px;
            margin-top: 2rem;
            line-height: 1.8;
        }

        a {
            color: $white;
            text-decoration: underline;
        }
    }
    .signup-form {
        padding-top: 20vh;

        @media screen and (max-width: 742px) {
            padding-top: 10vh;
        }
    }
    .col2 {
        h1 {
            margin-bottom: 2rem;
        }
        form {
            max-width: 450px;
            margin: 0 auto;
        }
        .extras {
            float: right;
            text-align: right;
            a {
                display: block;
                margin-bottom: 0.5rem;
            }
        }

        .password-reset {
            h1 {
                margin-bottom: 1rem;
            }
            p {
                margin-bottom: 2rem;
            }
        }
    }
}

// dashboard
#dashboard {
    section {
        padding: 2rem 0;
    }

    .col1 {
        flex: 0 0 30%;

        @media screen and (max-width: 1000px) {
    		flex: 0 0 40%;
    	}
    }
    .profile {
        padding: 2rem;
        background: $white;

        h5 {

        }

        p {
            margin-bottom: 1rem;
            color: $medium;
        }
    }
    .create-post {
        p {
            margin-bottom: 0.5rem;
        }
        textarea {
            height: 200px;
            margin: 0;
        }
        .button {
            z-index: 5;
            width: 100%;
            margin-top: 1rem;
            border-radius: 0 0 3px 3px;
        }
    }
    .hidden-posts {
        font-size: 14px;
        text-align: center;
        background: $white;
        border: 1px solid #e6ecf0;
        border-bottom: 0;

        &:hover {
            cursor: pointer;
            background: #f5f8fa;
        }

        .new-posts {
            color: $primary;
        }
        p {
            padding: 15px;
            margin: 0 auto;
            color: darken($light, 25%);
        }
    }
    .post {
        padding: 1.5rem;
        font-size: 14px;
        background: $white;
        border: 1px solid #e6ecf0;
        border-bottom: 0;

        &:hover {
            background: #f5f8fa;
        }

        &:last-of-type {
            border-bottom: 1px solid $light;
        }

        h5 {
            margin: 0 0 0.5rem;
        }
        span {
            display: block;
            margin-bottom: 0.5rem;
            font-size: 12px;
            font-style: italic;
            color: darken($light, 25%);
        }
        p {
            margin: 0;
        }
        ul {
            padding: 0;
            margin: 1rem 0 0;
            list-style: none;

            li {
                display: inline-block;
                margin-right: 1rem;
                font-size: 12px;
            }
        }
    }
    .no-results {
        padding: 100px 1rem;
        text-align: center;
        background: $white;
        border: 1px solid #e6ecf0;
    }
    .c-modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        background: rgba($dark, 0.4);

        .c-container {
            position: relative;
            max-width: 600px;
            padding: 2rem;
            margin: 30vh auto 0;
            background: $white;
            border-radius: 3px;
            box-shadow: 0 0 20px 0 rgba($dark, 0.5);

            a {
                position: absolute;
                top: 5px;
                right: 10px;
                padding: 5px;
            }
            .button {
                margin-top: 1rem;
            }
        }
    }
    .p-modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        background: rgba($dark, 0.4);

        .p-container {
            position: fixed;
            top: 2vh;
            right: 0;
            bottom: 2vh;
            left: 0;
            max-width: 600px;
            padding: 2rem;
            margin: 0 auto;
            overflow: auto;
            background: $white;
            border-radius: 3px;
            box-shadow: 0 0 20px 0 rgba($dark, 0.5);

            .close {
                position: absolute;
                top: 5px;
                right: 10px;
                padding: 5px;
            }

            .post {
                border: 0;
                border-bottom: 1px solid #e6ecf0;

                &:hover {
                    background: $white;
                }
            }

            .comment {
                padding: 1.5rem;
                font-size: 14px;
                border-top: 1px solid #e6ecf0;

                &:first-of-type {
                    border-top: 0;
                }

                h5 {
                    margin: 0 0 0.5rem;
                }
                span {
                    display: block;
                    margin-bottom: 0.5rem;
                    font-size: 12px;
                    font-style: italic;
                    color: darken($light, 20%);
                }
                p {
                    margin: 0;
                }
            }
        }
    }
}

// settings
#settings {
    padding: 2rem 0;

    .col1 {
        max-width: 600px;
        padding: 2rem;
        margin: 5vh auto 0;
        background: $white;
    }

    form {
        margin-top: 3rem;
    }

    .success {
        margin: 0.5rem 0 -2rem;
        color: $success;
    }

    .button {
        margin-top: 2rem;
    }
}
