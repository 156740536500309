// MLE Corporate Colors
$mle-color: #000;
$mle-backcolor: #fff;

$mle-darkgrey: #616161;
$mle-medgrey: #929292;
$mle-lightgrey: #d7d7d7;

$mle-red: #9a0b0b;
$mle-yellow: #beaa38;
$mle-green: #305b2e;

$mle-blue: #30278c;

// Common name translations for use inside code
// $mle-color: #000;    black
// $mle-backcolor: #fff;   white

// $mle-darkgrey: #616161;   grey darken-1
// $mle-med1grey: #929292;   grey lighten-2
// $mle-med2grey: #b8b8b8;   grey lighten-4
// $mle-lightgrey: #d7d7d7;

// $mle-red: #9a0b0b;  red darken-3
// $mle-yellow: #beaa38;  yellow darken-2
// $mle-green: #305b2e;  green darken-3
// for buttons : green, grey

// $mle-blue: #30278c;  blue darken-3

// Used Color Tokens
$color-text: $mle-darkgrey;
$color-heading-text: $mle-lightgrey;
$color-link-text: $mle-green;

// CONFIDENCE
$color-confidence-low-text: $mle-red;
$color-confidence-medium-text: $mle-yellow;
$color-confidence-high-text: $mle-green;
$color-confidence-unknown-text: $mle-color;
