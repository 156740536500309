















































































































































































































































































































































































































































































































































































































































































































































































































































@import '@design';
.appBarMle {
  right: 0 !important;
  z-index: 20 !important;
  color: $mle-color;
  background: black !important;
}

.link {
  @extend %font-heading;

  color: whitesmoke !important;
  text-decoration: none;
}

.headerTitle {
  @extend %font-heading;

  color: whitesmoke !important;
  letter-spacing: 3px;
}
.copyrightNotice {
  @extend %font-heading;

  color: whitesmoke !important;
  letter-spacing: 1px;
}
