










































@import '@design';

.container {
  width: 100%;
  height: calc(100vh);
  // margin-top: 64px;
  overflow: hidden;
}

.content {
  width: inherit;
  height: inherit;
  padding: 0 20px;
  overflow: auto;
  background-color: #eee;
}
