// imports
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

// colors
$primary: #30A0EE;
$error: #ef5777;
$success: #1abc9c;
$light: #F5F8FA;
$medium: #657786;
$dark: #34495E;
$white: #fff;
// resets
body {
	margin: 0;
	color: $dark;
	background: #E6ECF0;
}

html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
}

* {
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
	transition: 0.15s;

	&:focus {
		outline: none;
	}
}
// fonts
h1,
h2,
h3,
h4,
h5,
p {
	margin: 0 0 0.5rem;
}

h1 {
	font-size: 2rem;
}

h2 {
	font-size: 1.8rem;
}

h3 {
	font-size: 1.6rem;
}

h4 {
	font-size: 1.4rem;
}

h5 {
	font-size: 1.2rem;
}

p {
	line-height: 1.5;
}

a {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	color: $primary;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		color: lighten($primary, 5%);
	}
}

// globals
section {
	display: flex;
	max-width: 1200px;
	margin: 0 auto;

	@media screen and (max-width: 742px) {
		display: block;
	}
}

.col1,
.col2 {
	flex-basis: 0;
	flex-grow: 1;
	padding: 1rem;

	@media screen and (max-width: 742px) {

	}
}

[v-cloak] {display: none}

.loading {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
	background: rgba($dark, 0.4);

	p {
		width: 150px;
        padding: 40px 0;
		margin: 40vh auto 0;
		text-align: center;
		background: $white;
		border-radius: 3px;
		box-shadow: 0 0 20px 0 rgba($dark, 0.5);
	}
}

.error-msg {
	margin-top: 1rem;
	text-align: center;
	p {
		margin: 0;
		color: $error;
	}
}

.button {
	min-width: 150px;
	padding: 0.8rem 1rem;
	font-size: 16px;
	color: $white;
	cursor: pointer;
	background: $primary;
	border: 0;
	border-radius: 3px;
	outline: 0;

	&:hover {
		background: lighten($primary, 5%);
	}
    &:disabled {
        opacity: 0.5;

        &:hover {
            background: $primary;
        }
    }
}

.text-center {
	text-align: center;
}

.inline {
	padding: 0;
	margin: 0;
	list-style: none;

	li {
		display: inline-block;
	}
}

.clear {
    clear: both;
}

form {
	label {
		display: block;
		margin-bottom: 0.5rem;
		font-size: 16px;
	}

	input {
		display: block;
		width: 100%;
		padding: 10px;
		margin-bottom: 1rem;
		font-size: 16px;
		border: 1px solid #e6ecf0;
		border-radius: 3px;
		outline: 0;

		&:focus {
			box-shadow: 0 0 5px 0 rgba($dark, 0.2);
		}
	}

    textarea {
        width: 100%;
        height: 100px;
        padding: 10px;
        font-size: 16px;
        resize: none;
        border: 1px solid #e6ecf0;
        outline: 0;
    }
}
// transitions
.fade-enter-active {
	transition: opacity 0.5s;
}
.fade-leave-active {
	transition: opacity 0s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
