@import 'colors';

.confidenceLow {
  color: $color-confidence-low-text;
}

.confidenceMedium {
  color: $color-confidence-medium-text;
}

.confidenceHigh {
  color: $color-confidence-high-text;
}

.confidenceUnknown {
  color: $color-confidence-unknown-text;
}

.toolbar {
  position: fixed;
  left: 0;
  // z-index: 10;
  width: 100%;
  // padding: 0px;
  background: $mle-backcolor;
}

.main {
  padding: 0 0 0 0;
}

.panel {
  padding: 1px 1px 1px 1px;
}

// this is area that surrounds the document.
.panelContainer {
  // padding: 0;
  // overflow-y: auto;
  // background: $mle-lightgrey;
  // background: $mle-backcolor;
  border-color: $mle-lightgrey;
  border-style: solid;
}
// this is the document that contains the clause label and text.
.panelmain {
  // max-width: 100rem;
  // height: 100rem; // setting this from 600 rem to 100 rem seems to shorten the panel, so
  // min-height: 100rem; // it does not use the web scrolling as much.  It tends to use more the panel scrolling which is good.
  height: 85vh; // this seems to work much better for scrolling as it never seems to activate the panel scrolling in the browser.
  padding: 0 0 0 0;
  margin: 0 auto;
  overflow-y: auto;
  // background: $mle-backcolor;
  // border-color: $mle-darkgrey;
  // border-style: solid;
}

.panelnav {
  position: fixed; /* Set the navbar to fixed position */
  // top: 10; /* Position the navbar at the top of the page */
  // width: 100%; /* Full width */
  // width: auto;
}

.navcard {
  // border-color: $mle-darkgrey;
  // border-style: solid;
  // border: 3px solid black;
}

// dbtest related only
// .dbtestbuttons {
//   display: block;
// }

// .panelButtons {
//   align-self: left;
// }

.paragraph {
  padding: 0 0 0 0;
  // margin-top: 50px;
  // margin-bottom: 0;
  font-family: 'Open-Sans', Helvetica, Arial, Lucida, sans-serif;
  text-align: left-justify;
  text-justify: inter-word;
  border-color: $mle-lightgrey;
  border-width: 1px;
  border-top-style: dashed;
  // opacity: 0;
  // height: 150px;
  // margin-bottom: 0;
}
.paragraph:hover {
  background-color: $mle-backcolor;
}

.clauseLabel {
  padding-top: 2px;
}

.clausePredictions {
  padding-top: 2px;
  text-align: right;
}

.clauseIndicator {
  padding-top: 2px;
  text-align: right;
}

.clauseText {
  padding-top: 2px;
}

.labelContainer {
  // width: 20px;
}

.label {
  // width: auto;
  padding: 2px 5px 2px 5px;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  cursor: pointer;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.labelWrap {
  // width: 78px;
  // padding-right: 5px;
  // margin-right: 15px;
}

.iconStyle {
  float: left;
  padding-top: 8px;
}

.dialogHeadline {
  padding: 16px 24px !important;
}
.dialogTextStyle {
  padding: 16px 24px !important;
  border-color: $mle-red;
  border-style: solid;
  // width: 100%;
}
.dialogInputText {
  width: 100%;
  font-size: 120%;
}
.dialogInputParagraph {
  width: 100%;
  font-size: 90%;
}
.dialogTitle {
  padding: 16px 24px !important;
  color: $mle-backcolor;
  background: $mle-color;
}

.dialogWidth {
  // max-width: 1000px;
}
.dialogRowTextWidth {
  min-width: 400px;
  height: auto;
  // font-size: 500%;
  // font-size: 24px;
  // text-align: left;
  // height: 60px;
  word-wrap: break-word;
  border-bottom: 1px solid #ddd;
}

.dialogSmallRowTextWidth {
  min-width: 50px;
  height: auto;
  // font-size: 500%;
  // font-size: 24px;
  // text-align: left;
  // height: 60px;
  word-wrap: break-word;
  border-bottom: 1px solid #ddd;
}

.dialogCellHeading {
  width: 20px;
  font-size: 30px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.dialogCellAction {
  width: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.dialogCellStatus {
  width: 20px;
  // height: 62px;
  // justify: left;
  // text-align: center;
  border-bottom: 1px solid #ddd;
}
.dialogBigCellStatus {
  width: 40px;
  // height: 62px;
  // justify: left;
  // text-align: center;
  border-bottom: 1px solid #ddd;
}

.dialogCellDelete {
  width: 5px;
  // height: 60px;
  // text-align: center;
  border-bottom: 1px solid #ddd;
}
.dialogCellWidth {
  width: 50px;
  // text-align: center;
  border-bottom: 1px solid #ddd;
}

.dialogTags {
  width: 150px;
  // text-align: center;
  border-bottom: 1px solid #ddd;
}

.dialogSentNumStatus {
  width: 20px;
  border-bottom: 1px solid #ddd;
}

.dialogDocEdits {
  min-width: 200px;
  height: auto;
  word-wrap: break-word;
  border-bottom: 1px solid #ddd;
}

.dialogOpenTable {
  min-height: 200px;
  overflow-x: auto;
  overflow-y: auto;
  // border-collapse: collapse;
  border: 3px solid black;
}

.dialogTable {
  width: 100%;
  // height: 300px;
  min-height: 200px;
  overflow-x: auto;
  // max-height: 350px;
  // height: auto;
  overflow-y: auto;
  // border-collapse: collapse;
  border: 3px solid black;
}
.dialogHead {
  border: 1px solid black;
}

.dialogHeader {
  // padding: 15px;
  // text-align: left;
  // border: 1px solid black;
  // font-size: 12px;
}
.dialogBody {
  // border: 1px solid black;
}

.dialogRow {
  height: auto;
  border: 1px solid black;
}
